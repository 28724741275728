<script setup lang="ts">
import { useLocalStorage } from "@core/composable/useLocalStorage"
import * as Sentry from "@sentry/browser";

const ability = useAbility()

definePage({
  meta: {
    layout: "blank",
    action: "access",
    subject: "UiAuth",
  },
})

const router = useRouter()

const userData = useLocalStorage<any>('userData')

const isMobile = computed(() => {
  return window.navigator.userAgent.includes("Mobile")
})

const logout = async () => {
  useLocalStorage('accessToken').value = null

  userData.value = null

  await router.push('/login')

  useLocalStorage('userAbilityRules').value = null

  ability.update([])
}

const redirect = async () => {
  $api('api/auth/me').then(res => {
    const {
      userData,
      userAbilityRules
    } = res

    ability.update(userAbilityRules)

    useLocalStorage('userAbilityRules').value = userAbilityRules
    useLocalStorage('userData').value = userData

    Sentry.setUser({
      id: userData.id,
      email: userData.email,
      name: userData.fullName,
    })

    // Redirect to the page
    if (ability.can('access', 'Dashboard') && !isMobile.value) {
      router.push({ name: "home-dashboard" })
    } else if (ability.can('access', 'OmsOrder')) {
      router.push({ name: 'oms-orders' })
    } else if (ability.can('access', 'OmsAsn')) {
      router.push({ name: 'oms-asns' })
    } else if (ability.can('access', 'OmsProduct')) {
      router.push({ name: 'oms-products' })
    } else if (ability.can('access', 'OmsInventory')) {
      router.push({ name: 'oms-inventories' })
    } else if (ability.can('access', 'WmsManagementInbound')) {
      router.push({ name: 'warehouse-management-inbound' })
    } else if (ability.can('access', 'WmsManagementOutbound')) {
      router.push({ name: 'warehouse-management-outbound' })
    } else if (ability.can('access', 'WmsManagementPickWave')) {
      router.push({ name: 'warehouse-management-pickwave' })
    } else if (ability.can('access', 'WmsManagementSku')) {
      router.push({ name: 'warehouse-management-sku' })
    } else if (ability.can('access', 'WmsManagementInventory')) {
      router.push({ name: 'warehouse-management-inventory' })
    } else if (ability.can('access', 'WmsManagementAdjustment')) {
      router.push({ name: 'warehouse-management-adjustment' })
    } else if (ability.can('access', 'WmsManagementCycleCount')) {
      router.push({ name: 'warehouse-management-cyclecount' })
    } else if (ability.can('access', 'WmsOperationReceiving')) {
      router.push({ name: 'warehouse-operation-receiving' })
    } else if (ability.can('access', 'WmsOperationPutaway')) {
      router.push({ name: 'warehouse-operation-putaway' })
    } else if (ability.can('access', 'WmsOperationPicking')) {
      router.push({ name: 'warehouse-operation-picking' })
    } else if (ability.can('access', 'WmsOperationSorting')) {
      router.push({ name: 'warehouse-operation-sorting' })
    } else if (ability.can('access', 'WmsOperationPacking')) {
      router.push({ name: 'warehouse-operation-packing' })
    } else if (ability.can('access', 'WmsOperationPalletization')) {
      router.push({ name: 'warehouse-operation-palletization' })
    } else if (ability.can('access', 'WmsOperationHandover')) {
      router.push({ name: 'warehouse-operation-handover' })
    } else if (ability.can('access', 'WmsOperationCycleCount')) {
      router.push({ name: 'warehouse-operation-cyclecount' })
    } else if (ability.can('access', 'WmsOperationRelocation')) {
      router.push({ name: 'warehouse-operation-relocation' })
    } else {
      router.push({ name: 'not-authorized' })
    }
  }).catch(err => {
    if (err.statusCode === 403) {
      logout()
    }
  })
}

redirect()
</script>
