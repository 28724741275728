import { getCountryData } from 'countries-list'
import moment from 'moment'
import pluralize from 'pluralize'
import type { App } from 'vue'
import { useLocalStorage } from "@core/composable/useLocalStorage"

export default function (app: App) {
  app.config.globalProperties.$filters = new Filters()
}

export class Filters {
  toCurrency(value: number, currency: string) {
    if (typeof value !== 'number')
      return value

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    })

    return formatter.format(value)
  }

  formatNumber(value: number, zeroString: string): string {
    if (value === 0 && zeroString != null)
      return zeroString

    return Intl.NumberFormat('en-US').format(value)
  }

  formatDate(value: string) {
    if (value)
      return moment(String(value)).format('YYYY-MM-DD')
  }

  formatDateLocale(value: string) {
    if (value)
      return moment(String(value)).format(this.getLocaleDateFormat())
  }

  formatDateTime(value: string) {
    if (value)
      return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
  }

  formatDateTimeLocale(value: string) {
    if (value)
      return moment(String(value)).format(`${this.getLocaleDateFormat()} ${this.getLocaleTimeFormat()}`)
  }

  // Un-localized
  formatDateAt(value: string) {
    if (value)
      return moment(String(value)).format('MMM D [at] h:mm a')
  }

  formatDateAtFull(value: string) {
    if (value)
      return moment(String(value)).format('MMMM D, YYYY [at] h:mm a')
  }

  formatDateFromNow(value: string) {
    if (value)
      return moment(String(value)).fromNow()
  }

  formatDateFull(value: string) {
    if (value)
      return moment(String(value)).format('MMMM D, YYYY')
  }

  formatAddress(address: string, address2: string, city: string, province: string, countryCode: string, postalCode: string) {
    const r = []
    if (address)
      r.push(address)
    if (address2)
      r.push(address2)
    if (city)
      r.push(city)
    if (province)
      r.push(province)
    if (countryCode) {
      const country = getCountryData(countryCode)
      if (typeof country != "undefined") r.push(country.name)
      else r.push(countryCode)
    }
    if (postalCode)
      r.push(postalCode)

    return r
  }

  formatStatus(value: string) {
    if (value)
      return value.charAt(0).toUpperCase() + value.replaceAll("_", " ").toLowerCase().slice(1)
  }

  getLocaleDateFormat() {
    const userData = useLocalStorage<any>('userData').value

    if (userData && userData.dateFormat)
      return userData.dateFormat.toUpperCase()
    else
      return 'YYYY-MM-DD'
  }

  getLocaleTimeFormat() {
    const userData = useLocalStorage<any>('userData').value

    if (userData && userData.timeFormat)
      return userData.timeFormat
    else
      return 'HH:mm:ss'
  }

  getLocaleDateFormatMask() {
    return this.getLocaleDateFormat().replace(/[a-zA-Z]/g, '#')
  }

  trim(value: string) {
    if (value)
      return value.trim()

    return value
  }

  pluralize(noun: string, value: number) {
    return pluralize(noun, value)
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $filters: Filters
  }
}
