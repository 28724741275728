<!-- ❗Errors in the form are set on line 60 -->
<script setup lang="ts">
import landingBackground from '@images/pages/landing-background.jpg'
import { VForm } from 'vuetify/components/VForm'

definePage({
  meta: {
    layout: 'blank',
    unauthenticatedOnly: true,
  },
})

const isPasswordVisible = ref(false)

const route = useRoute()
const router = useRouter()

const ability = useAbility()

const isApp = computed(() => {
  return typeof App != 'undefined'
})

const hasCamera = ref(false)

const errors = ref<Record<string, string | undefined>>({
  email: undefined,
  password: undefined,
})

const refVForm = ref<VForm>()

const credentials = ref({
  email: '',
  password: '',
})

const isError = ref(false)
const isLoadingDialogVisible = ref(false)
const errorMessage = ref('')

const isLoginByQrcode = ref(false)
const qrcode = ref('')
const qrcodeField = ref(null)

const login = async () => {
  isLoadingDialogVisible.value = true

  const res = $api('/api/auth/login', {
    method: 'POST',
    body: {
      email: credentials.value.email,
      password: credentials.value.password,
      qrcode: qrcode.value,
    },
  }).then(res => {
    const { accessToken, userData, userAbilityRules } = res

    useLocalStorage('userAbilityRules').value = userAbilityRules

    ability.update(userAbilityRules)

    useLocalStorage('userData').value = userData
    useLocalStorage('accessToken').value = accessToken

    isLoadingDialogVisible.value = false

    // Redirect to `to` query if exist or redirect to index route
    // ❗ nextTick is required to wait for DOM updates and later redirect
    nextTick(() => {
      router.replace(route.query.to ? String(route.query.to) : '/')
    })
  }).catch(e => {
    isError.value = true
    errorMessage.value = $parseError(e)
    console.log(e)

    isLoadingDialogVisible.value = false
  })
}

const loginByQRCode = () => {
  isLoginByQrcode.value = true
  credentials.value.email = null
  credentials.value.password = null

  nextTick(() => {
    qrcodeField.value.focus()
  })
}

const loginByUsernamePassword = () => {
  isLoginByQrcode.value = false
  qrcode.value = null

  if (isApp.value) {
    window.App.enableKeyboard()
  }
}

const scanByCamera = () => {
  if (isApp.value) {
    window.App.scanner()
  }
}

const onSubmit = () => {
  refVForm.value?.validate()
    .then(({ valid: isValid }) => {
      if (isValid)
        login()
    })
}

onMounted(() => {
  if (isApp.value) {
    window.addEventListener('message', (event) => {
      let result = event.data

      if (typeof event.data != "object") {
        result = JSON.parse(event.data)
      }

      if (result.method && result.method === 'scanner') {
        qrcode.value = result.value
        login()
      } else if (result.method && result.method === 'hasCamera') {
        hasCamera.value = result.value
      }
    })

    if (typeof window.App.hasCamera != 'undefined') {
      window.App.hasCamera()
    }

    loginByQRCode()
  }
})
</script>

<template>
  <VSnackbar
    color="error"
    v-model="isError"
    :timeout="2000"
  >
    {{ errorMessage }}
  </VSnackbar>
  <VDialog
    v-model="isLoadingDialogVisible"
    :width="$windowWidth < 600 ? $windowWidth : 600"
    persistent
  >
    <VCard
      color="secondary"
      :width="$windowWidth < 600 ? $windowWidth : 600"
    >
      <VCardText class="py-2 px-2 py-lg-6">
        Logging in...
        <VProgressLinear
          indeterminate
          color="primary"
          :height="8"
          class="mb-0 mt-4"
        />
      </VCardText>
    </VCard>
  </VDialog>
  <VRow
    no-gutters
    class="auth-wrapper bg-surface"
  >
    <VCol
      lg="8"
      class="d-none d-lg-flex"
    >
      <div
        class="position-relative bg-background w-100 ma-0 me-0"
        style="background-repeat: repeat;"
        :style="{ backgroundImage: `url(${landingBackground})` }"
      />
    </VCol>

    <VCol
      cols="12"
      lg="4"
      class="auth-card-v2 d-flex align-center justify-center"
    >
      <VCard
        flat
        :width="$windowWidth < 400 ? $windowWidth : 400"
        class="mt-sm-0"
      >
        <VCardText v-if="false">
          <p class="mb-0">
            Please sign-in to your account.
          </p>
        </VCardText>

        <VCardText>
          <VForm
            ref="refVForm"
            @submit.prevent="onSubmit"
          >
            <VRow>
              <!-- email -->
              <VCol
                v-if="!isLoginByQrcode"
                cols="12"
                style="block-size:120px"
              >
                <AppTextField
                  v-model="credentials.email"
                  label="Email"
                  density="comfortable"
                  placeholder="johndoe@email.com"
                  type="email"
                  autofocus
                  :rules="[requiredValidator, emailValidator]"
                  :error-messages="errors.email"
                />
              </VCol>

              <!-- password -->
              <VCol
                v-if="!isLoginByQrcode"
                cols="12"
                style="block-size:120px"
              >
                <AppTextField
                  v-model="credentials.password"
                  density="comfortable"
                  label="Password"
                  placeholder="············"
                  :rules="[requiredValidator]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :error-messages="errors.password"
                  :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />
              </VCol>

              <!-- QRCode -->
              <VCol
                v-if="isLoginByQrcode"
                cols="12"
                style="block-size:120px"
              >
                <VTextField
                  ref="qrcodeField"
                  v-model="qrcode"
                  density="comfortable"
                  label="Scan QRCode here"
                  @keyup.enter="login"
                />
              </VCol>

              <VCol cols="12">
                <VBtn
                  v-if="!isLoginByQrcode"
                  class="mt-2"
                  block
                  type="submit"
                  size="x-large"
                >
                  <VIcon
                    start
                    icon="tabler-login"
                  />
                  Login
                </VBtn>

                <VBtn
                  v-if="isLoginByQrcode"
                  class="mt-2"
                  block
                  size="x-large"
                  @click="login"
                >
                  <VIcon
                    start
                    icon="tabler-login"
                  />
                  Login
                </VBtn>

                <VBtn
                  v-if="!isLoginByQrcode"
                  class="mt-2"
                  block
                  size="x-large"
                  color="secondary"
                  @click="loginByQRCode"
                >
                  <VIcon
                    start
                    icon="tabler-barcode"
                  />
                  Login by QRCode
                </VBtn>

                <VBtn
                  v-if="isLoginByQrcode"
                  class="mt-2"
                  block
                  size="x-large"
                  color="secondary"
                  @click="loginByUsernamePassword"
                >
                  <VIcon
                    start
                    icon="tabler-barcode"
                  />
                  Login by Email
                </VBtn>

                <VBtn
                  v-if="isLoginByQrcode && isApp && hasCamera"
                  class="mt-2"
                  block
                  color="info"
                  size="x-large"
                  @click="scanByCamera"
                >
                  <VIcon
                    start
                    icon="tabler-camera"
                  />
                  Scan by Camera
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
