<script setup lang="ts">
import { useLocalStorage } from "@core/composable/useLocalStorage"

definePage({
  alias: '/pages/misc/not-authorized',
  meta: {
    layout: 'blank',
    public: true,
  },
})

const router = useRouter()
const ability = useAbility()

const userData = useLocalStorage<any>('userData')

const logout = async () => {
  useLocalStorage('accessToken').value = null

  userData.value = null

  await router.push('/login')

  useLocalStorage('userAbilityRules').value = null

  ability.update([])
}
</script>

<template>
  <div class="misc-wrapper">
    <ErrorHeader
      status-code="401"
      title="You are not authorized! 🔐"
      description="You do not have permission to view this page using the credentials that you have provided while login.
       Please contact your site administrator."
    />

    <VBtn
      class="mt-2 mb-2"
      to="/"
      style="inline-size:150px"
    >
      <VIcon
        icon="tabler-home"
        start
      />
      Back Home
    </VBtn>

    <VBtn
      class="mt-2 mb-10"
      style="inline-size:150px"
      @click="logout"
    >
      Logout
      <VIcon
        icon="tabler-logout"
        end
      />
    </VBtn>
  </div>
</template>

<style lang="scss">
@use "@core/scss/template/pages/misc.scss";
</style>
