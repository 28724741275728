import _definePage_default_0 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/[...error].vue?definePage&vue'
import _definePage_default_3 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/forgot-password.vue?definePage&vue'
import _definePage_default_4 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/login.vue?definePage&vue'
import _definePage_default_5 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/not-authorized.vue?definePage&vue'
import _definePage_default_6 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/oms/asns/[...].vue?definePage&vue'
import _definePage_default_7 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/oms/inventories/[...].vue?definePage&vue'
import _definePage_default_8 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/oms/orders/[...].vue?definePage&vue'
import _definePage_default_9 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/oms/products/[...].vue?definePage&vue'
import _definePage_default_10 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/adjustment/[...].vue?definePage&vue'
import _definePage_default_11 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/cyclecount/[...].vue?definePage&vue'
import _definePage_default_12 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/inbound/[...].vue?definePage&vue'
import _definePage_default_13 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/inventory/[...].vue?definePage&vue'
import _definePage_default_14 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/outbound/[...].vue?definePage&vue'
import _definePage_default_15 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/pickwave/[...].vue?definePage&vue'
import _definePage_default_16 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/pickwave-automation/[...].vue?definePage&vue'
import _definePage_default_17 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/sku/[...].vue?definePage&vue'
import _definePage_default_18 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/inventory-search/[...].vue?definePage&vue'
import _definePage_default_19 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/receiving/[...].vue?definePage&vue'
import _definePage_default_20 from '/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/vas/[...].vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'root',
    component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/developer-tool',
    /* internal name: 'developer-tool' */
    /* no component */
    children: [
      {
        path: 'app',
        name: 'developer-tool-app',
        component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/developer-tool/app.vue'),
        /* no children */
        meta: {
          "layout": "default",
          "subject": "UiAuth",
          "action": "access"
        }
      },
      {
        path: 'label',
        name: 'developer-tool-label',
        component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/developer-tool/label.vue'),
        /* no children */
        meta: {
          "layout": "default",
          "subject": "UiAuth",
          "action": "access"
        }
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/forgot-password.vue'),
    /* no children */
  },
  _definePage_default_3
  ),
  {
    path: '/home',
    /* internal name: 'home' */
    /* no component */
    children: [
      {
        path: 'dashboard',
        name: 'home-dashboard',
        component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/home/dashboard.vue'),
        /* no children */
        meta: {
          "action": "access",
          "subject": "Dashboard"
        }
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_4
  ),
  _mergeRouteRecord(
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/not-authorized.vue'),
    /* no children */
  },
  _definePage_default_5
  ),
  {
    path: '/oms',
    /* internal name: 'oms' */
    /* no component */
    children: [
      {
        path: 'asns',
        /* internal name: 'oms-asns' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'oms-asns',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/oms/asns/[...].vue'),
            /* no children */
          },
  _definePage_default_6
  )
        ],
      },
      {
        path: 'inventories',
        /* internal name: 'oms-inventories' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'oms-inventories',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/oms/inventories/[...].vue'),
            /* no children */
          },
  _definePage_default_7
  )
        ],
      },
      {
        path: 'orders',
        /* internal name: 'oms-orders' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'oms-orders',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/oms/orders/[...].vue'),
            /* no children */
          },
  _definePage_default_8
  )
        ],
      },
      {
        path: 'products',
        /* internal name: 'oms-products' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'oms-products',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/oms/products/[...].vue'),
            /* no children */
          },
  _definePage_default_9
  )
        ],
      }
    ],
  },
  {
    path: '/report',
    /* internal name: 'report' */
    /* no component */
    children: [
      {
        path: 'outbound-container-handover-summary',
        name: 'report-outbound-container-handover-summary',
        component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/report/outbound-container-handover-summary.vue'),
        /* no children */
        meta: {
          "layout": "default",
          "action": "access",
          "subject": "Report"
        }
      },
      {
        path: 'pick-wave-summary',
        name: 'report-pick-wave-summary',
        component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/report/pick-wave-summary.vue'),
        /* no children */
        meta: {
          "layout": "default",
          "action": "access",
          "subject": "Report"
        }
      },
      {
        path: 'warehouse-performance',
        name: 'report-warehouse-performance',
        component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/report/warehouse-performance.vue'),
        /* no children */
        meta: {
          "layout": "default",
          "action": "access",
          "subject": "Report"
        }
      },
      {
        path: 'weekly-usage',
        name: 'report-weekly-usage',
        component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/report/weekly-usage.vue'),
        /* no children */
        meta: {
          "layout": "default",
          "action": "access",
          "subject": "Report"
        }
      }
    ],
  },
  {
    path: '/setting',
    /* internal name: 'setting' */
    /* no component */
    children: [
      {
        path: 'global-variable',
        name: 'setting-global-variable',
        component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/global-variable.vue'),
        /* no children */
        meta: {
          "layout": "default",
          "action": "access",
          "subject": "SettingChannel"
        }
      },
      {
        path: 'integration',
        /* internal name: 'setting-integration' */
        /* no component */
        children: [
          {
            path: 'channel',
            /* internal name: 'setting-integration-channel' */
            /* no component */
            children: [
              {
                path: '',
                name: 'setting-integration-channel',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/integration/channel/[...].vue'),
                /* no children */
                meta: {
                  "layout": "default",
                  "action": "access",
                  "subject": "SettingChannel"
                }
              }
            ],
          },
          {
            path: 'courier',
            /* internal name: 'setting-integration-courier' */
            /* no component */
            children: [
              {
                path: '',
                name: 'setting-integration-courier',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/integration/courier/[...].vue'),
                /* no children */
                meta: {
                  "layout": "default",
                  "action": "access",
                  "subject": "SettingCourier"
                }
              }
            ],
          },
          {
            path: 'inventory-sync',
            /* internal name: 'setting-integration-inventory-sync' */
            /* no component */
            children: [
              {
                path: '',
                name: 'setting-integration-inventory-sync',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/integration/inventory-sync/[...].vue'),
                /* no children */
                meta: {
                  "layout": "default",
                  "action": "access",
                  "subject": "SettingCourier"
                }
              }
            ],
          },
          {
            path: 'routing-rule',
            /* internal name: 'setting-integration-routing-rule' */
            /* no component */
            children: [
              {
                path: '',
                name: 'setting-integration-routing-rule',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/integration/routing-rule/[...].vue'),
                /* no children */
                meta: {
                  "layout": "default",
                  "action": "access",
                  "subject": "SettingCourier"
                }
              }
            ],
          }
        ],
      },
      {
        path: 'permission',
        /* internal name: 'setting-permission' */
        /* no component */
        children: [
          {
            path: '',
            name: 'setting-permission',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/permission/[...].vue'),
            /* no children */
            meta: {
              "layout": "default",
              "action": "access",
              "subject": "SettingUser"
            }
          }
        ],
      },
      {
        path: 'role',
        /* internal name: 'setting-role' */
        /* no component */
        children: [
          {
            path: '',
            name: 'setting-role',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/role/[...].vue'),
            /* no children */
            meta: {
              "layout": "default",
              "action": "access",
              "subject": "SettingUser"
            }
          }
        ],
      },
      {
        path: 'system',
        /* internal name: 'setting-system' */
        /* no component */
        children: [
          {
            path: ':tab',
            name: 'setting-system-tab',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/system/[tab].vue'),
            /* no children */
            meta: {
              "navActiveLink": "pages-user-profile-tab",
              "layout": "default",
              "subject": "SettingSystem",
              "action": "access"
            }
          }
        ],
      },
      {
        path: 'user',
        /* internal name: 'setting-user' */
        /* no component */
        children: [
          {
            path: '',
            name: 'setting-user',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/user/[...].vue'),
            /* no children */
            meta: {
              "layout": "default",
              "action": "access",
              "subject": "SettingUser"
            }
          }
        ],
      },
      {
        path: 'user-profile',
        /* internal name: 'setting-user-profile' */
        /* no component */
        children: [
          {
            path: ':tab',
            name: 'setting-user-profile-tab',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/user-profile/[tab].vue'),
            /* no children */
            meta: {
              "layout": "default",
              "subject": "UiAuth",
              "action": "access"
            }
          }
        ],
      },
      {
        path: 'warehouse',
        /* internal name: 'setting-warehouse' */
        /* no component */
        children: [
          {
            path: ':tab',
            name: 'setting-warehouse-tab',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/warehouse/[tab].vue'),
            /* no children */
            meta: {
              "navActiveLink": "pages-user-profile-tab",
              "layout": "default",
              "subject": "SettingWarehouse",
              "action": "access"
            }
          },
          {
            path: 'basket',
            /* internal name: 'setting-warehouse-basket' */
            /* no component */
            children: [
              {
                path: '',
                name: 'setting-warehouse-basket',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/warehouse/basket/[...].vue'),
                /* no children */
                meta: {
                  "layout": "default",
                  "action": "access",
                  "subject": "SettingWarehouse"
                }
              }
            ],
          },
          {
            path: 'consumable',
            /* internal name: 'setting-warehouse-consumable' */
            /* no component */
            children: [
              {
                path: '',
                name: 'setting-warehouse-consumable',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/warehouse/consumable/[...].vue'),
                /* no children */
                meta: {
                  "layout": "default",
                  "action": "access",
                  "subject": "SettingWarehouse"
                }
              }
            ],
          },
          {
            path: 'location-area',
            /* internal name: 'setting-warehouse-location-area' */
            /* no component */
            children: [
              {
                path: '',
                name: 'setting-warehouse-location-area',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/warehouse/location-area/[...].vue'),
                /* no children */
                meta: {
                  "layout": "default",
                  "action": "access",
                  "subject": "SettingWarehouse"
                }
              }
            ],
          },
          {
            path: 'location-bin',
            /* internal name: 'setting-warehouse-location-bin' */
            /* no component */
            children: [
              {
                path: '',
                name: 'setting-warehouse-location-bin',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/warehouse/location-bin/[...].vue'),
                /* no children */
                meta: {
                  "layout": "default",
                  "action": "access",
                  "subject": "SettingWarehouse"
                }
              }
            ],
          },
          {
            path: 'location-type',
            /* internal name: 'setting-warehouse-location-type' */
            /* no component */
            children: [
              {
                path: '',
                name: 'setting-warehouse-location-type',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/warehouse/location-type/[...].vue'),
                /* no children */
                meta: {
                  "layout": "default",
                  "action": "access",
                  "subject": "SettingWarehouse"
                }
              }
            ],
          },
          {
            path: 'location-zone',
            /* internal name: 'setting-warehouse-location-zone' */
            /* no component */
            children: [
              {
                path: '',
                name: 'setting-warehouse-location-zone',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/warehouse/location-zone/[...].vue'),
                /* no children */
                meta: {
                  "layout": "default",
                  "action": "access",
                  "subject": "SettingWarehouse"
                }
              }
            ],
          },
          {
            path: 'reason-code',
            /* internal name: 'setting-warehouse-reason-code' */
            /* no component */
            children: [
              {
                path: '',
                name: 'setting-warehouse-reason-code',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/setting/warehouse/reason-code/[...].vue'),
                /* no children */
                meta: {
                  "layout": "default",
                  "action": "access",
                  "subject": "SettingWarehouse"
                }
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/warehouse',
    /* internal name: 'warehouse' */
    /* no component */
    children: [
      {
        path: 'management',
        /* internal name: 'warehouse-management' */
        /* no component */
        children: [
          {
            path: 'adjustment',
            /* internal name: 'warehouse-management-adjustment' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'warehouse-management-adjustment',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/adjustment/[...].vue'),
                /* no children */
              },
  _definePage_default_10
  )
            ],
          },
          {
            path: 'cyclecount',
            /* internal name: 'warehouse-management-cyclecount' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'warehouse-management-cyclecount',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/cyclecount/[...].vue'),
                /* no children */
              },
  _definePage_default_11
  )
            ],
          },
          {
            path: 'inbound',
            /* internal name: 'warehouse-management-inbound' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'warehouse-management-inbound',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/inbound/[...].vue'),
                /* no children */
              },
  _definePage_default_12
  )
            ],
          },
          {
            path: 'inventory',
            /* internal name: 'warehouse-management-inventory' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'warehouse-management-inventory',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/inventory/[...].vue'),
                /* no children */
              },
  _definePage_default_13
  )
            ],
          },
          {
            path: 'outbound',
            /* internal name: 'warehouse-management-outbound' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'warehouse-management-outbound',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/outbound/[...].vue'),
                /* no children */
              },
  _definePage_default_14
  )
            ],
          },
          {
            path: 'pickwave',
            /* internal name: 'warehouse-management-pickwave' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'warehouse-management-pickwave',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/pickwave/[...].vue'),
                /* no children */
              },
  _definePage_default_15
  )
            ],
          },
          {
            path: 'pickwave-automation',
            /* internal name: 'warehouse-management-pickwave-automation' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'warehouse-management-pickwave-automation',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/pickwave-automation/[...].vue'),
                /* no children */
              },
  _definePage_default_16
  )
            ],
          },
          {
            path: 'sku',
            /* internal name: 'warehouse-management-sku' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'warehouse-management-sku',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/management/sku/[...].vue'),
                /* no children */
              },
  _definePage_default_17
  )
            ],
          }
        ],
      },
      {
        path: 'operation',
        /* internal name: 'warehouse-operation' */
        /* no component */
        children: [
          {
            path: 'containerization',
            name: 'warehouse-operation-containerization',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/containerization.vue'),
            /* no children */
            meta: {
              "layout": "default",
              "action": "access",
              "subject": "WmsOperationContainerization"
            }
          },
          {
            path: 'cyclecount',
            /* internal name: 'warehouse-operation-cyclecount' */
            /* no component */
            children: [
              {
                path: '',
                name: 'warehouse-operation-cyclecount',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/cyclecount/[...].vue'),
                /* no children */
                meta: {
                  "layout": "default",
                  "action": "access",
                  "subject": "WmsOperationCycleCount"
                }
              }
            ],
          },
          {
            path: 'handover',
            name: 'warehouse-operation-handover',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/handover.vue'),
            /* no children */
            meta: {
              "layout": "default",
              "action": "access",
              "subject": "WmsOperationHandover"
            }
          },
          {
            path: 'inventory-search',
            /* internal name: 'warehouse-operation-inventory-search' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'warehouse-operation-inventory-search',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/inventory-search/[...].vue'),
                /* no children */
              },
  _definePage_default_18
  )
            ],
          },
          {
            path: 'packing',
            name: 'warehouse-operation-packing',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/packing.vue'),
            /* no children */
            meta: {
              "layout": "default",
              "action": "access",
              "subject": "WmsOperationPacking"
            }
          },
          {
            path: 'picking',
            /* internal name: 'warehouse-operation-picking' */
            /* no component */
            children: [
              {
                path: '',
                name: 'warehouse-operation-picking',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/picking/[...].vue'),
                /* no children */
                meta: {
                  "layout": "default",
                  "action": "access",
                  "subject": "WmsOperationPicking"
                }
              }
            ],
          },
          {
            path: 'picking-paperless',
            /* internal name: 'warehouse-operation-picking-paperless' */
            /* no component */
            children: [
              {
                path: '',
                name: 'warehouse-operation-picking-paperless',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/picking-paperless/[...].vue'),
                /* no children */
                meta: {
                  "layout": "default",
                  "action": "access",
                  "subject": "WmsOperationPicking"
                }
              }
            ],
          },
          {
            path: 'putaway',
            name: 'warehouse-operation-putaway',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/putaway.vue'),
            /* no children */
            meta: {
              "layout": "default",
              "action": "access",
              "subject": "WmsOperationPutaway"
            }
          },
          {
            path: 'receiving',
            /* internal name: 'warehouse-operation-receiving' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'warehouse-operation-receiving',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/receiving/[...].vue'),
                /* no children */
              },
  _definePage_default_19
  )
            ],
          },
          {
            path: 'relocation',
            name: 'warehouse-operation-relocation',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/relocation.vue'),
            /* no children */
            meta: {
              "layout": "default",
              "action": "access",
              "subject": "WmsOperationRelocation"
            }
          },
          {
            path: 'sorting',
            name: 'warehouse-operation-sorting',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/sorting.vue'),
            /* no children */
            meta: {
              "layout": "default",
              "action": "access",
              "subject": "WmsOperationSorting"
            }
          },
          {
            path: 'stock-alert',
            name: 'warehouse-operation-stock-alert',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/stock-alert.vue'),
            /* no children */
            meta: {
              "layout": "default",
              "action": "access",
              "subject": "WmsOperationRelocation"
            }
          },
          {
            path: 'tiktok-live-panel',
            name: 'warehouse-operation-tiktok-live-panel',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/tiktok-live-panel.vue'),
            /* no children */
            meta: {
              "layout": "default",
              "action": "access",
              "subject": "WmsOperationTiktok"
            }
          },
          {
            path: 'trolley-status',
            name: 'warehouse-operation-trolley-status',
            component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/trolley-status.vue'),
            /* no children */
            meta: {
              "layout": "default",
              "action": "access",
              "subject": "WmsOperation"
            }
          },
          {
            path: 'vas',
            /* internal name: 'warehouse-operation-vas' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'warehouse-operation-vas',
                component: () => import('/home/tiger/WebstormProjects/packnship-frontend/src/pages/warehouse/operation/vas/[...].vue'),
                /* no children */
              },
  _definePage_default_20
  )
            ],
          }
        ],
      }
    ],
  }
]
