import type { App } from 'vue'

import * as Sentry from "@sentry/vue"

export default function (app: App) {
  // Only in production
  if (!import.meta.env.PROD) {
    return
  }

  Sentry.init({
    app,
    dsn: "https://f8d18f136ff14ef442fd78cdea7a0ed7@o4507891511721984.ingest.de.sentry.io/4507891515392080",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    ignoreErrors: [
      /FetchError/i,
      /Failed to fetch dynamically imported module/i,
      // ...
    ],

    // Error
    attachStacktrace: true,

    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["demo.packnship.app", "demo-api.packnship.app", "gmc-uk.packnship.app", "gmc-uk-api.packnship.app"],

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.您咩
  })
}
