export type LocalStorageRef<T> = Ref<T>

export const useLocalStorage = <T = any | null | undefined>(name: string, defaultValue: T = null): LocalStorageRef<T> => {
  const rawValue: string = localStorage.getItem(name)
  const storage = ref<T | undefined>()

  if (rawValue == null) {
    storage.value = defaultValue
  } else {
    storage.value = JSON.parse(rawValue)
  }

  watch(storage, () => {
    localStorage.setItem(name, JSON.stringify(storage.value))
  })

  return storage as LocalStorageRef<T>
}
